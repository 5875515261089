import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import Seo from '../components/Seo';
import InvestmentCard from '../components/InvestmentCard';
import InvestmentCardAlt from '../components/InvestmentCardAlt';

const InvestmentsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        investments: allMarkdownRemark(
          sort: {
            order: [
              ASC,
              ASC
            ],
            fields: [
              frontmatter___weight,
              frontmatter___title
            ]
          },
          filter: {
            fileAbsolutePath: {
              regex : "/\/investments\//"
            }
            frontmatter: {
              type: {
                eq: "current"
              },
              published: {
                eq: true
              }
            }
          },
          limit: 1000
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                blurb
                title
                weight
                logo {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200,
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        },
        investments_acquisitions: allMarkdownRemark(
          sort: {
            order: [
              ASC,
              ASC
            ],
            fields: [
              frontmatter___weight,
              frontmatter___title
            ]
          },
          filter: {
            fileAbsolutePath: {
              regex : "/\/investments\//"
            }
            frontmatter: {
              type: {
                eq: "acquisition"
              },
              published: {
                eq: true
              }
            }
          },
          limit: 1000
        ) {
          edges {
            node {
              id
              frontmatter {
                blurb
                title
                logo_alt {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200,
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        },
        investments_prior: allMarkdownRemark(
          sort: {
            order: [
              ASC,
              ASC
            ],
            fields: [
              frontmatter___weight,
              frontmatter___title
            ]
          },
          filter: {
            fileAbsolutePath: {
              regex : "/\/investments\//"
            }
            frontmatter: {
              type: {
                eq: "prior investment"
              },
              published: {
                eq: true
              }
            }
          },
          limit: 1000
        ) {
          edges {
            node {
              id
              frontmatter {
                blurb
                title
                logo_alt {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200,
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        },
      }
    `}
    render={data => (
      <section className="bg-white mx-auto py-12 lg:pb-20 px-4 sm:px-6 lg:px-8 max-w-lg sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
        <header className="pb-4">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">Investments</h1>
        </header>
        {/* Main */}
        <h2 className="text-2xl font-bold">
          Current Investments
        </h2>
        {/* eslint-disable max-len */}
        <div className="inv-1 p-2 sm:p-0 grid xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 lg:gap-8 xl:gap-12 mx-auto max-w-sm sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
          {data.investments.edges && data.investments.edges.map(({ node }, i) => (
            <InvestmentCard
              node={node}
              index={i}
              noLazyAboveFold={true}
              borderColour="black-haze"
            />
          ))}
        </div>
        <h2 className="text-2xl font-bold mt-8">
          Acquisitions
        </h2>
        {/* eslint-disable max-len */}
        <div className="inv-2 p-2 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-8 lg:gap-12 mx-auto max-w-sm sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
          {data.investments_acquisitions.edges && data.investments_acquisitions.edges.map(({ node }) => (
            <InvestmentCardAlt
              node={node}
            />
          ))}
        </div>
        <h2 className="text-2xl font-bold mt-8">
          Previous Investments
        </h2>
        {/* eslint-disable max-len */}
        <div className="inv-3 p-2 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-8 lg:gap-12 mx-auto max-w-sm sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
          {data.investments_prior.edges && data.investments_prior.edges.map(({ node }) => (
            <InvestmentCardAlt
              node={node}
            />
          ))}
        </div>
        <Seo
          slug="/investments/"
          title="Investments"
        />
      </section>
    )}
  />
);

export default InvestmentsPage;
