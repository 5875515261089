import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const InvestmentCardAlt = ({ node }) => {
  return (
    <div id="inv-card" key={`{inv-${node.id}`} className="relative flex items-center justify-center text-center" data-aos-once="true" data-aos="zoom-in">
      <StaticImage
        src="../images/placeholder-tile.jpg"
        placeholder="none"
        backgroundColor="transparent"
        alt=""
        loading="lazy"
        role="presentation"
        objectFit="fill"
        formats={['auto', 'webp', 'avif']}
        className={`border-2 sm:border-4 lg:border-6 border-gray-50`}
      />
      <div className="block absolute w-full px-6">
        {node.frontmatter.logo_alt
          && (
            <GatsbyImage
              image={node.frontmatter.logo_alt.childImageSharp.gatsbyImageData}
              loading="lazy"
              alt={node.frontmatter.title}
              objectFit="fill"
            />
          )
        }
        <div className="blurb text-xs sm:text-smaller lg:text-sm pt-2">
          {node.frontmatter.blurb}
        </div>
      </div>
    </div>
  );
};

InvestmentCardAlt.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      logo_alt: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({}),
        }),
      }),
      title: PropTypes.string,
      blurb: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default InvestmentCardAlt;
