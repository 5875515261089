import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const InvestmentCard = ({ node, index, noLazyAboveFold, borderColour }) => {
  const loading = noLazyAboveFold && (index < 8) ? 'eager' : 'lazy';
  return (
    <div id="inv-card" key={`{inv-${node.id}`} className="relative flex items-center justify-center text-center" data-aos-once="true" data-aos="zoom-in">
      <StaticImage
        src="../images/placeholder-tile.jpg"
        placeholder="none"
        backgroundColor="transparent"
        alt=""
        loading={loading}
        objectFit="fill"
        className={`border-4 lg:border-6 border-${borderColour}`}
      />
      <div className="block absolute w-full px-6">
        <Link to={`${node.fields.slug}`} className="block no-underline text-outer-space">
          {node.frontmatter.logo
            && (
              <GatsbyImage
                image={node.frontmatter.logo.childImageSharp.gatsbyImageData}
                loading={loading}
                alt={node.frontmatter.title}
                objectFit="fill"
                className="mx-8 mt-4 sm:mx-4 sm:mt-0"
              />
            )
          }
          <div className="blurb text-md sm:text-smaller lg:text-sm pt-4">
            {node.frontmatter.blurb}
          </div>
        </Link>
      </div>
    </div>
  );
};

InvestmentCard.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    frontmatter: PropTypes.shape({
      logo: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({}),
        }),
      }),
      title: PropTypes.string,
      blurb: PropTypes.string,
    }).isRequired,
  }).isRequired,
  index: PropTypes.number,
  noLazyAboveFold: PropTypes.bool,
  borderColour: PropTypes.string,
};

export default InvestmentCard;
